import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import { Snackbar } from '@mui/material';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function App() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [marker, setMarker] = useState(null);
  const [zoom, setZoom] = useState(17);
  const [heading, setHeading] = useState(0);
  const [geolocationError, setGeolocationError] = useState(false);

  useEffect(() => {
    const handleOrientation = (event) => {
      if (event.alpha !== null) {
        setHeading(event.alpha);
      }
    };

    window.addEventListener('deviceorientation', handleOrientation, true);

    const successCallback = (position) => {
      const lng = position.coords.longitude;
      const lat = position.coords.latitude;

      if (!map.current && mapContainer.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [lng, lat],
          zoom: zoom
        });

        const newMarker = new mapboxgl.Marker({
          color: 'blue'
        })
          .setLngLat([lng, lat])
          .addTo(map.current);

        setMarker(newMarker);

        map.current.on('zoomend', () => {
          setZoom(map.current.getZoom());
        });
      } else if (marker) {
        marker.setLngLat([lng, lat]);
      }
    };

    const errorCallback = (error) => {
      console.error('Geolocation error:', error);
      setGeolocationError(true);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
        enableHighAccuracy: true,
        timeout: 10000, // Aumentar el tiempo de espera
        maximumAge: 0 // No usar caché
      });

      const watchId = navigator.geolocation.watchPosition(successCallback, errorCallback, {
        enableHighAccuracy: true,
        timeout: 10000, // Aumentar el tiempo de espera
        maximumAge: 0 // No usar caché
      });

      return () => {
        navigator.geolocation.clearWatch(watchId);
        window.removeEventListener('deviceorientation', handleOrientation);
      };
    }
  }, [marker, zoom]);

  useEffect(() => {
    if (marker) {
      marker.getElement().style.transform = `rotate(${heading}deg)`;
    }
  }, [heading, marker]);

  return (
    <div className="map-wrapper">
      <div ref={mapContainer} className="map-container" />
      <Snackbar
        open={geolocationError}
        autoHideDuration={6000}
        onClose={() => setGeolocationError(false)}
        message="Error de geolocalización: tiempo de espera agotado"
      />
    </div>
  );
}

export default App;